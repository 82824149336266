import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfig } from './app/Shared/app-config.inject';
import { IPublicClientApplication, PublicClientApplication, InteractionType, LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(environment.syncfusionKey);

if (environment.production) {
  enableProdMode();
}

function bootstrapApp() {
  const req = new XMLHttpRequest();

  req.addEventListener('error', (res: any) => {
    console.log('Error: ', res);
  });
  let url = window.location.href.includes('/prospect/screenpopup?phone') ? true : false;
  //to auto login if user session already exists --start--
  var sessionStorage_transfer = function (event) {
    if (!event) { event = window.event; };
    if (!event.newValue) return;
    if (event.key == 'getSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key == 'sessionStorage' && (!sessionStorage.length || url)) {
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  };

  if (window.addEventListener) {
    window.addEventListener("storage", sessionStorage_transfer, false);
  } else {
    //window.attachEvent("onstorage", sessionStorage_transfer);
    (<any>window).attachEvent("onstorage", sessionStorage_transfer);
  };
  if (!sessionStorage.length || url) {
    localStorage.setItem('getSessionStorage', 'foobar');
    localStorage.removeItem('getSessionStorage');
  };
  //to auto login if user session already exists --End--
  req.addEventListener('load', (res: any) => {
    let appConfig = JSON.parse(res.currentTarget.response);
    environment.defaultCountryId = appConfig.defaultCountryId;
    environment.esignatureBaseURL = appConfig.esignatureBaseURL;
    environment.ringcentralWidgetURL = appConfig.ringcentralWidgetURL;
    environment.appInsights.instrumentationKey = appConfig.WebAppInsightsInstrumentationKey;
    environment.isFingerPrintEnabled = appConfig.isFingerPrintEnabled;
    environment.ringcentralIVREnabled = appConfig.ringcentralIVREnabled;
    environment.ringcentralClientNumbers = appConfig.ringcentralClientNumbers;
    environment.isDLScannerEnabled = appConfig.isDLScannerEnabled;
    environment.dlScannerlicenseKey = appConfig.dlScannerlicenseKey;
    environment.consentScopes = appConfig.consentScopes;
    environment.paymentGetCCTStatusInterval = appConfig.paymentGetCCTStatusInterval;
    environment.paymentMaxWaitTime = appConfig.paymentMaxWaitTime;
    environment.paymentMaxWaitTime = appConfig.paymentMaxWaitTime;
    environment.allowToCreatePortalLogin = appConfig.allowToCreatePortalLogin;
    environment.multipleCountryForNearestLocation = appConfig.multipleCountryForNearestLocation;
    environment.autoSaveInquiryTime = appConfig.autoSaveInquiryTime;
    environment.isTopazSignatureEnabled = appConfig.isTopazSignatureEnabled;
    environment.syncfusionKey = appConfig.syncfusionKey;
    environment.authority = appConfig.authority;
    environment.clientID = appConfig.clientID;
    environment.redirectUri = appConfig.redirectUri;
    environment.postLogoutRedirectUri = appConfig.postLogoutRedirectUri;
    environment.protectedResourceMap = appConfig.protectedResourceMap;
    environment.tdLocalHostArchitectureFacilities = appConfig.tdLocalHostArchitectureFacilities;
    environment.enableNewPaymentArchitecture = appConfig.enableNewPaymentArchitecture;
    environment.prePayMaxLimit = appConfig.prePayMaxLimit;
    environment.useLocalStorageMsal = appConfig.useLocalStorageMsal == true || appConfig.useLocalStorageMsal == "true" ? true : false;
    // let item = sessionStorage.getItem("isForgotPassword");
    // if(item == "true") {
    //   let tempAppConfig;
    //   tempAppConfig = JSON.parse(sessionStorage.getItem('appConfig'));
    //   if (tempAppConfig) {
    //     tempAppConfig.authority = tempAppConfig.authority.replace('B2C_1A_ForcePasswordReset_signup_signin_esoft2', 'B2C_1A_PasswordReset_SignInName_OtherEmail_PassReset');
    //     appConfig = tempAppConfig;
    //   }
    //   sessionStorage.setItem('isForgotPassword', 'false');
    // } else {
    //   sessionStorage.setItem('isForgotPassword', 'false');
    //   sessionStorage.setItem('appConfig', res.currentTarget.response);
    // }


    //////// MSAL 2.0 ////////////////////
    const b2cPolicies = {
      names: {
        SignUpSignIn: 'B2C_1_NextGen_SignIn',
        resetPassword: 'B2C_1_Password_Reset',
      },
      authorities: {
        signUpSignIn: {
          authority: environment.authority,
        },
        resetPassword: {
          authority:  environment.authority.replace('B2C_1_NextGen_SignIn', 'B2C_1_Password_Reset'),
        }
      },
      authorityDomain: environment.authority.split('/')[2],
    };

    const msalConfig: Configuration = {
      auth: {
        clientId: environment.clientID,
        navigateToLoginRequestUrl: true, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: environment.redirectUri, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        // Points to window.location.origin by default
      },
      cache: {
        cacheLocation: environment.useLocalStorageMsal ? BrowserCacheLocation.LocalStorage : BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel: LogLevel, message: string) {
          },
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      }
    }

    const loginRequest = {
      scopes: ['']
    }

    function MSALGuardConfigFactory(): MsalGuardConfiguration {
      return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
      };
    }

    function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
      const protectedResourceMap = new Map<string, Array<string>>();
      protectedResourceMap.set(environment.protectedResourceMap[0][0], [environment.protectedResourceMap[0][1][0]]);
      // protectedResourceMap.set("https://app-nextgenesoft-presentation-stg.azurewebsites.net/api/", ["https://nextgenssmstg.onmicrosoft.com/nextgenapi/read"]);
      return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
      };
    }

    function MSALInstanceFactory(): IPublicClientApplication {
      return new PublicClientApplication(msalConfig);
    }

    console.log('config', appConfig);

    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      {
        provide: AppConfig, useValue: {
          ...appConfig
        }
      },
    ]).bootstrapModule(AppModule)
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('/ngsw-worker.js');
        }
      })
      .catch(err => console.error(err));
  });

  req.open('GET', '/assets/config/config.json');
  req.send(null);
}

bootstrapApp();


